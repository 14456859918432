import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/departments/DepartmentLayout';

interface Props {
  data: {
    erikJones2: Core.ImageParagraphWrapImageProps;
    conflict: Core.ImageAttributes;
    engagement: Core.ImageAttributes;
    femaleProfessors: Core.ImageAttributes;
    migration: Core.ImageAttributes;
    usChina: Core.ImageAttributes;
    kentCalder: Core.ImageParagraphWrapImageProps;
    saisBolognaImpact: Core.ImageAttributes;
  };
}

const DepartmentContent: React.FC<Props> = (props) => {
  const {
    erikJones2,
    conflict,
    engagement,
    femaleProfessors,
    migration,
    usChina,
    kentCalder,
    saisBolognaImpact
  } = props.data;

  return (
    <Layout departmentSlug="dispatch" title="Dispatch">
      <Core.ContentWrapper>
        <Core.ContentSection>
          <Core.DepartmentLabel>Dispatch</Core.DepartmentLabel>
          <Core.Flag>SAIS News</Core.Flag>
        </Core.ContentSection>

        <Core.ContentSection
          id="steps-forward-in-diversity-equity-and-inclusion"
          showShareLink
        >
          <Core.PrimaryHeading>
            Steps Forward in Diversity, Equity, and Inclusion
          </Core.PrimaryHeading>

          <p>
            It has been six months since the Crowell Committee submitted its
            report of concrete, actionable recommendations to SAIS leadership to
            advance the school’s diversity, equity, and inclusion efforts.
          </p>
          <p>
            Of the myriad of ideas and recommendations that were submitted, Dean
            Eliot Cohen determined that in the time remaining in his tenure, the
            priority areas of focus would be to create a role at leadership team
            level, reporting to the dean, that would help guide diversity
            efforts at SAIS and ensure accountability; develop ways of expanding
            recruitment of underrepresented minority students and faculty; and
            begin to develop external partnerships to help align SAIS with
            organizations that support international relations practitioners.
          </p>
          <p>
            In December, Dean Cohen expanded Miji Bell’s role from director of
            communications to senior director of marketing, communications, and
            community engagement. Bell now serves as the dean’s principal
            adviser on diversity, equity and inclusion issues and provides
            oversight for diversity strategy at SAIS. In addition, she
            collaborates with faculty and staff to cultivate and implement
            initiatives and programs that seek to enhance diversity across the
            entire SAIS community. She also develops external partnerships and
            represents SAIS on the University-wide Diversity Leadership Council.
            Bell joined SAIS in 2020 and has an extensive background as a
            diversity trainer and conference leader and has advised corporate
            and nonprofit entities on crisis response strategy and best
            practices for prioritizing and advancing a climate of diversity and
            inclusion.
          </p>
          <p>
            In recent months, Bell has advised leadership on a number of
            diversity-related issues and has served as diversity adviser to the
            search committee for the Burling Chair in International Law, which
            yielded several candidates from underrepresented minority groups.
          </p>
          <p>
            As SAIS seeks to become more intentional about efforts to strengthen
            its minority student recruitment pipeline, Cohen and SAIS leaders
            from academic affairs, global careers, and admissions met in March
            with senior academic officers from several historically black
            colleges and universities (HBCUs), including Spelman College, Morgan
            State University, Bennett College, and Howard University for a
            dynamic discussion about how to develop short- and long-term
            SAIS/HBCU pipeline development opportunities.
          </p>
          <p>
            The SAIS Office of Global Careers has begun meeting quarterly with
            members of the International Career Advancement Program to develop
            ways for SAIS to partner with their ICAP Fellows and ICAP Aspen
            initiatives. This partnership will connect SAIS students and alumni
            directly to ICAP’s support network of mentors and career advising
            for underrepresented mid-career minority professionals pursuing
            careers in international affairs.
          </p>
          <p>
            SAIS recently joined the Think Tank Diversity Consortium, which
            consists of senior leaders from more than 30 think tanks, including
            Brookings, Aspen Institute, AEI, Pacific Council, Pew Research
            Center, Women of Color Advancing Peace and Security, and a host of
            others. The consortium is focused on increasing access to paid
            internship and employment opportunities for underrepresented
            minorities and on providing a collective means of holding
            organizations accountable for exemplifying diversity within their
            recruitment and retention practices, and helping to dismantle
            institutional racism within their organizational processes and
            systems.
          </p>
          <p>
            Internally, the SAIS Diversity Council — which consists of faculty,
            staff, and students — continues to actively assist with planning and
            facilitating programming that honors the diversity of the SAIS
            community with a myriad of events held during Black History Month,
            Women’s History Month, and throughout the year in recognition of
            other celebrations and heritage months. The Council has also made
            recommendations to Cohen for diversifying guest speakers for the
            Dean’s Speaker Series and other key programs. The SAIS Office of
            Student Affairs and Academic Affairs also works closely with the
            Dean’s Office to address diversity-related concerns from students
            and faculty, and to connect members of the SAIS community to support
            resources as needed within the JHU Office of Diversity and
            Inclusion.
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection
          id="summer-transition-for-leadership"
          showShareLink
        >
          <Core.PrimaryHeading>
            Summer Transition for Leadership
          </Core.PrimaryHeading>

          <Core.ImageParagraphWrap image={kentCalder}>
            In May, Johns Hopkins University leaders announced a summer
            transition plan for the leadership of SAIS. These interim
            appointments will serve as the school’s temporary leaders after Dean
            Eliot Cohen concludes his term and current Vice Deans Andrew Mertha
            and Filipe Campante step down at the end of June.
          </Core.ImageParagraphWrap>

          <p>
            Provost Sunil Kumar has appointed{' '}
            <a href="https://sais.jhu.edu/users/kcalder2">Kent Calder</a>,
            Director of the Edwin O. Reischauer Center for East Asian Studies,
            as interim Dean of SAIS beginning July 1, following the conclusion
            of Dean Cohen’s term. Calder previously served as Vice Dean for
            Faculty Affairs and International Research Cooperation from 2018 to
            2020 and Director of Asia Programs from 2016 to 2018.Prior to his
            arrival at SAIS in 2003, he served as Special Advisor to the U.S.
            Ambassador to Japan, Japan Chair at the Center for Strategic and
            International Studies (CSIS), professor at Princeton University, and
            the first Executive Director of Harvard University’s Program on
            U.S.–Japan Relations.
          </p>
          <p>
            In addition,{' '}
            <a href="https://sais.jhu.edu/users/jfanzo1">Jessica Fanzo</a>,
            Bloomberg Distinguished Professor of Global Food Policy and Ethics,
            and <a href="https://sais.jhu.edu/users/plewis18">Peter Lewis</a>,
            the Warren Weinstein Chair of African Studies and Director of Africa
            and Middle East Programs at SAIS, will assume interim leadership
            roles at the school beginning July 1.
          </p>

          <p>
            Fanzo will serve as interim Vice Dean for Faculty Affairs at the
            conclusion of Mertha’s term. She joined SAIS in 2015 and holds
            appointments at the University’s Berman Institute of Bioethics and
            Bloomberg School of Public Health. She currently leads the Johns
            Hopkins Global Food Policy and Ethics program and serves as Director
            of Food and Nutrition Security within the University’s Alliance for
            a Healthier World initiative.
          </p>
          <p>
            Lewis will serve as interim Vice Dean for Education and Academic
            Affairs at the conclusion of Campante’s term. He has previously
            served as the school’s Vice Dean for Academic and Faculty Affairs
            from 2015 to 2018. Since joining SAIS in 2006, Lewis has directed
            SAIS’ Africa program and also currently oversees its Middle East
            program. His research and teaching focus on economic reform and
            political transition is in developing countries, with particular
            emphasis on governance and development in Sub-Saharan Africa.
          </p>
          <p>
            University leaders note that the search for the next dean of SAIS is
            proceeding on schedule. It is expected that the new dean will be in
            place prior to the fall semester.{' '}
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection
          id="jones-to-lead-schuman-center-in-florence"
          showShareLink
        >
          <Core.PrimaryHeading>
            Jones to Lead Schuman Center in Florence
          </Core.PrimaryHeading>

          <Core.ImageParagraphWrap image={erikJones2}>
            Erik Jones, SAIS professor of European Studies and International
            Political Economy, has announced plans to take a leave of absence
            from SAIS to serve as the director of the Robert Schuman Center for
            Advanced Studies at the European University Institute in Florence,
            starting in September.
          </Core.ImageParagraphWrap>

          <p>
            Jones has been a key member of the faculty at SAIS Europe and an
            important part of the entire SAIS community for almost two decades,
            including currently as director of the Master of Arts in Global
            Risk, director of European and Eurasian Studies, and in the past as
            director of the Bologna Institute for Policy Research.
          </p>
          <p>
            In addition to teaching, Jones has written four books and edited or
            co-edited more than 30 books or special issues of journals relating
            to European politics and economy. He is co-editor of{' '}
            <em>Government &amp; Opposition</em>, a contributing editor of
            <em>Survival</em>, and a frequent commentator on European political
            and economic events, who has written pieces in{' '}
            <em>The New York Times</em>, <em>Financial Times</em>, and many
            publications across Europe.
          </p>
          <p>
            Jones, who earned his AB from Princeton University in 1988, went on
            to earn both his MA and PhD from Johns Hopkins SAIS (1990, 1996).
            Prior to joining the faculty at Johns Hopkins, he worked at the
            Centre for European Policy Studies, the Central European University,
            and the University of Nottingham.
          </p>
          <p>
            “[My] time at SAIS Europe has been transformative,” he says. “When I
            came to Bologna in 1988, I never imagined I would spend the next 33
            years studying Europe, and the last 20 years teaching at SAIS. I
            cannot even begin to list all the amazing experiences I had or
            colleagues and students I have had the chance to work with.
          </p>
          <p>
            “Even now, I cannot stop thinking about ways to connect people to
            SAIS or to show the world what SAIS graduates can do. I am
            enormously proud of my connection to this institution and my
            relationships with the students, alumni, and colleagues who make it
            such a great place to be. The fact that the European University
            Institute has invited me to direct the Robert Schuman Centre for
            Advanced Studies is only possible because of my SAIS experience and
            because of the tremendous strengths of the SAIS European Studies
            tradition.”
          </p>
          <p>
            Professor Jones will finish this academic year at SAIS Europe and
            will continue to be engaged with us in the future. We are looking
            forward to welcoming him back after his leave.
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection id="sais-europe-faculty-exchange" showShareLink>
          <Core.PrimaryHeading>
            SAIS Europe Faculty Exchange
          </Core.PrimaryHeading>

          <p>
            SAIS Europe offers its students exposure to a range of faculty
            members, including SAIS Europe resident faculty, adjunct faculty
            (European scholars and policymakers), and visiting professors from
            across SAIS&rsquo; different campuses. The unique mix facilitates
            the cross-pollination of ideas, enables imaginative scholarship, and
            provides students the best possible professional education in
            international affairs. &nbsp;Cross-campus exchange is further
            enhanced by the Integration fund, an essential part of the{' '}
            <a href="https://europe.jhu.edu/initiatives/special-jamatchingfund.cfm">
              James Anderson Matching Gift Program
            </a>
            , which provides SAIS Europe with the financial flexibility to pool
            talent from across SAIS&rsquo; different campuses and with Homewood.
          </p>

          <p>
            In the upcoming academic years, students at SAIS Europe will have
            the opportunity to work with{' '}
            <a href="https://sais.jhu.edu/users/carroyo1">
              <Core.BoldText>Cristino R. Arroyo</Core.BoldText>
            </a>{' '}
            (2021&ndash;22 and 2022&ndash;23),{' '}
            <a href="https://sais.jhu.edu/users/efinkel4,">
              <Core.BoldText>Eugene (Evgeny) Finkel</Core.BoldText>
            </a>{' '}
            (2021&ndash;22 and 2022&ndash;23),{' '}
            <a href="https://sais.jhu.edu/users/jfanzo1">
              <Core.BoldText>Jessica Fanzo</Core.BoldText>
            </a>{' '}
            (2021&ndash;22), and{' '}
            <a href="https://sais.jhu.edu/users/dhonig3">
              <Core.BoldText>Daniel Honig</Core.BoldText>
            </a>{' '}
            (spring 2022). In addition{' '}
            <a href="https://bipr.jhu.edu/events/1466-Robert-A-Mundell-Global-Risk-Annual-Lecture.cfm">
              <Core.BoldText>Guido Sandleris</Core.BoldText>
            </a>{' '}
            will be in residence in Bologna for the spring of 2022.
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.Flag>SAIS in the News</Core.Flag>

        <Core.ContentSection
          id="female-professors-have-less-time-for-research"
          showShareLink
          marginalia={
            <Core.FullWidthImage
              image={femaleProfessors}
              alt="Female Professors"
            />
          }
        >
          <Core.SecondaryHeading>
            Female Professors Have Less Time for Research
          </Core.SecondaryHeading>

          <p>
            In an interview with <em>The Washington Post</em> (April 5),
            Assistant Professor of Political Economy{' '}
            <Core.BoldText>Ling Chen</Core.BoldText> added her voice to those of
            other women in the world of academia who say that the COVID-19
            pandemic has put them at a major disadvantage when compared to their
            male colleagues.
          </p>

          <p>
            “On your CV, you can’t say, ‘I didn’t get things done this year
            because day care was closed,’” said Ling Chen, mother of a
            3-year-old daughter. In a normal year, she probably would have
            published two articles, submitted two more, and finished at least a
            third of her book, she said. Instead, she was only able to submit
            one article. She hasn’t touched the book. “My CV will reflect the
            gaps,” said Chen.{' '}
            <a href="https://www.thelily.com/female-professors-have-less-time-to-research-in-the-pandemic-it-could-force-them-out-of-academia-experts-say/">
              Read more
            </a>
            .
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection
          id="lessons-from-history-for-us-china-relations"
          showShareLink
          marginalia={<Core.FullWidthImage image={usChina} alt="US China" />}
        >
          <Core.SecondaryHeading>
            Lessons From History for U.S.-China Relations
          </Core.SecondaryHeading>
          <p>
            Cold War history shows that diplomacy can still play a critical role
            in U.S.-China relations if U.S. officials view negotiation as a tool
            of competition rather than a replacement for it, writes{' '}
            <Core.BoldText>Hal Brands</Core.BoldText>, the Henry A. Kissinger
            Distinguished Professor of Global Affairs at SAIS, in{' '}
            <em>Bloomberg Opinion</em> (March 31).
          </p>
          <p>
            The new era of competition and distrust must include realistic, even
            ruthless, negotiation, Brands contends. “Diplomacy is not an
            alternative to competition; it is a means of prosecuting competition
            more effectively,” he writes.{' '}
            <a href="https://www.bloomberg.com/opinion/articles/2021-03-31/-the-cold-war-s-lessons-for-u-s-china-diplomacy?srnd=opinion">
              Read more
            </a>
            .
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection
          id="changing-americans-views-on-migration"
          showShareLink
          marginalia={<Core.FullWidthImage image={migration} alt="Migration" />}
        >
          <Core.SecondaryHeading>
            Changing Americans’ Views on Migration
          </Core.SecondaryHeading>
          <p>
            “He came in with this portfolio. He did what he could. But it’s a
            speck in an ocean.” So says{' '}
            <Core.BoldText>Francisco González</Core.BoldText>, Riordan Roett
            associate professor of Latin American Studies, in a{' '}
            <em>Politico</em> article (April 3), speaking about then-Vice
            President Joe Biden’s efforts to address burgeoning migration from
            El Salvador and Honduras during his earlier tenure in the Oval
            Office.
          </p>
          <p>
            “Did it solve the problem? No, obviously, it didn’t. Did they
            allocate enough resources? No, it was nowhere near enough. And did
            then-Vice President Biden end up knowing more about the U.S.
            southern border and Central America? The answer is, yes, he did,”
            notes González, in an article that explores Biden’s current efforts
            to change how Americans think about migration.{' '}
            <a href="https://www.politico.com/news/2021/04/03/biden-migration-border-479000">
              Read more
            </a>
            .
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection
          id="iran-and-israel-covert-conflict"
          showShareLink
          marginalia={<Core.FullWidthImage image={conflict} alt="US Chine" />}
        >
          <Core.SecondaryHeading>
            Iran and Israel: Covert Conflict
          </Core.SecondaryHeading>
          <p>
            In light of Israeli attacks on Iranian ships, a semicovert conflict
            between the arch foes has intensified, notes <em>Inside Arabia</em>{' '}
            in “A New Front in the Covert Conflict Between Iran and Israel”
            (March 26).
          </p>
          <p>
            <Core.BoldText>Raffaella A. Del Sarto</Core.BoldText>, Associate
            Professor of Middle East Studies at SAIS Europe, tells writer
            Mohammad Hashemi: “In terms of both psychology and
            logistics/capabilities, the normalization of relations between
            Israel and the Emirates and Bahrain on the one hand, and the (still)
            covert good relations with Saudi Arabia and other Gulf states, give
            a new impetus to Israel’s containment and deterrence strategy
            vis-à-vis Iran.”{' '}
            <a href="https://insidearabia.com/a-new-front-in-the-covert-conflict-between-iran-and-israel/">
              Read more
            </a>
            .
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection
          id="shedding-light-on-chinas-engagement-in-africa"
          showShareLink
          marginalia={<Core.FullWidthImage image={engagement} alt="US Chine" />}
        >
          <Core.SecondaryHeading>
            Shedding Light on China’s Engagement in Africa
          </Core.SecondaryHeading>
          <p>
            In <em>Quartz Africa</em>,{' '}
            <Core.BoldText>Deborah Bräutigam</Core.BoldText>, who created the
            China-Africa Research Initiative at SAIS in 2014 to improve
            understanding of China’s engagement in Africa, discusses stereotypes
            that persist and the next stage of China-Africa relations (April 1).
          </p>
          <p>
            Bräutigam cites two key areas of focus of African governments in
            their future engagement with China: “First, passing legislation or
            complying with legislation that provides domestic accountability and
            oversight on lending. By this I mean things like independent debt
            management offices or giving parliaments the right to sign off on
            central government borrowing or anything that has a government
            guarantee,” she says. “Second, I do think African countries should
            have a prerequisite that every public project should have
            competitive bidding. And if they can’t get a competitive bid it
            doesn’t happen. This would ensure value for money for citizens and
            not over-stretching.”{' '}
            <a href="https://qz.com/africa/1991048/china-africa-experts-on-the-stereotypes-that-persist/">
              Read more
            </a>
            .
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Core.ContentSection
          id="students-share-their-covid-19-experiences"
          showShareLink
        >
          <Core.Flag>Sphere of Influence</Core.Flag>
          <Core.SecondaryHeading>
            Students Share Their COVID-19 Experiences
          </Core.SecondaryHeading>
          <p>
            “The SAIS expectation of academic excellence never wavered, but the
            additional obstacle became surviving a global pandemic, being in
            isolation, and balancing one&rsquo;s mental health.” So said{' '}
            <Core.BoldText>Jelani Legohn</Core.BoldText>, a California native
            who is completing his MA in International Affairs at the SAIS Europe
            campus in Bologna.
          </p>
          <p>
            Having campuses on three continents is one of the aspects that makes
            SAIS unique among schools of international affairs. During the
            global pandemic, however, there was nowhere to go to escape the
            virus. The effects of a once-in-a-century COVID-19 pandemic have
            been pervasive and highly disruptive all around the globe. This
            challenge forced SAIS to adapt quickly to meet needs of the new
            virtual reality, finding new ways to improve instruction, organize
            teaching, and better integrate among SAIS’ campuses in Washington,
            D.C., SAIS Europe, the Hopkins-Nanjing Center (HNC), and Baltimore.
          </p>
          <Core.FullWidthImage
            image={saisBolognaImpact}
            alt="SAIS Bologna Impact"
          />
          <p>
            SAIS has given students the flexibility to continue their graduate
            education in unconventional ways. For example, when the pandemic
            caused the SAIS D.C. campus to close, offering only online classes,
            some students from Washington, D.C., and Nanjing were able to travel
            to Bologna, where they attended their classes virtually while having
            opportunities to engage in activities in person with their Bologna
            counterparts.
          </p>
          <p>
            We asked some of our current students about their experiences across
            the three campuses as they faced the challenges from COVID-19:
          </p>
          <p>
            <Core.BoldText>Rhea Menon</Core.BoldText>, a native of India,
            graduated from the College of William and Mary in Williamsburg,
            Virginia, and then returned to India for several years to work for
            Carnegie India. She enrolled in the SAIS joint program to receive a
            Certificate in Chinese and American Studies plus Master of Arts in
            International Relations, intending to spend the first year in
            Nanjing. Instead, due to the pandemic, she remained in India, but is
            able to attend HNC classes virtually. She has had to cope with more
            than “just” the time zone difference: At the height of growing
            India-China tensions, China restricted the access of residents of
            India to certain class materials on Chinese websites.
            Notwithstanding the difficulties, Menon says she is grateful for the
            opportunity to spend time at home with her family, especially after
            having spent so much time living and studying abroad.
          </p>
          <p>
            Hailing from Hamburg, Germany, with an undergraduate degree in
            economics and political science from the University of Gottingen,{' '}
            <Core.BoldText>Moritz Luetgerath</Core.BoldText> came to SAIS Europe
            in the fall of 2020 to pursue his master’s in global risk degree.
            The first semester he attended all classes in person, but the
            government-imposed restrictions that began in February 2021 meant
            that all classes were virtual. Moritz believes that “the SAIS
            Bologna administration has done a great job at communicating the
            COVID-19 rules at SAIS Bologna as well as the uncertainties we
            continue to face. In the process, it has made weathering the
            pandemic a community effort, giving the students ownership of the
            challenge.”
          </p>
          <p>
            <Core.BoldText>Laura Rong</Core.BoldText> grew up in Nanjing, China,
            leaving at age 18 to attend the University of North Carolina at
            Chapel Hill, and expecting to remain abroad for work or study. But
            after the first year of her Master of Arts in International
            Relations study at SAIS Europe, she returned to Nanjing, where she
            found a unique opportunity at the Hopkins-Nanjing Center. She is
            able to study in the HNC library, use their facilities, and
            socialize with graduate students enrolled in the HNC program, while
            attending virtual classes originating from SAIS D.C.
          </p>
          <p>
            Rong has had to adjust to a big time difference — such as getting up
            at 2:30 a.m. to attend a class that ends at 5 a.m. As for her next
            steps, she says, “Since COVID-19 happened and tensions rose between
            China and the West, I started to rethink my future.” She says, “I
            cannot deny the opportunities I got because of COVID-19, but this is
            not what I originally planned for my life two years ago.” That plan
            included interning in a think tank in D.C. or Europe, attending SAIS
            D.C., working as a researcher in the U.S., and traveling in Italy.
            Due to the current travel restrictions, she has applied for a PhD
            program in Shanghai, but hopes to be able to attend an international
            program instead.
          </p>
          <p>
            <Core.BoldText>Jelani Legohn</Core.BoldText> is from Oxnard,
            California. In 2018, he graduated from the University of California,
            Davis, with a BA degree in both linguistics and international
            relations. When the pandemic began, he had been in his first year of
            the MAIA program at SAIS Europe, but returned home to California,
            completing the semester taking virtual classes originating in
            Bologna. In August 2020, he returned to SAIS Europe to finish his
            degree program in person, only to find that in early 2021, the city
            ordinance barred second-year students from attending in-person
            classes.
          </p>
          <p>
            Grateful that he has had the opportunity to spend more time with his
            family, Jelani misses being able to go out dancing, “finishing exams
            and celebrating at Guilio&rsquo;s cafe at the Bologna campus or
            spending the night out with the local Bolognese.” He has found
            comfort in the SAIS Europe community during the Italian lockdown,
            like his roommate, <Core.BoldText>Alexis Keys</Core.BoldText>, who
            is also finishing her MAIA at SAIS Europe and “understands the
            unique challenges of coping with the current state of the world
            while trying to finish a master’s degree at SAIS.”
          </p>
          <p>
            <Core.BoldText>Shiselle Povedano</Core.BoldText> is originally from
            Spain and grew up in Florida, earning both a BA in public relations
            and a BS in economics from University of Florida. She is in the last
            semester of her two-year, D.C.-based SAIS MA, concentrating in
            African Studies with a specialization in emerging markets. For
            Shiselle, the second-semester change — whereby each student was
            allowed to take one in-person class — was her “lifeline and one of
            the biggest helps to get through this last semester.” In contrast,
            she found the virtual classes to be extremely challenging, as
            professors faced difficulties coping with the video technology.
          </p>
          <p>
            Reflecting on life during the pandemic, Shiselle hopes that this
            experience gives students a greater understanding of “what it feels
            like to be trapped, to be unable to have a choice over seeing your
            family, mourn them, be ‘free.’ This is an experience a lot of
            immigrants deal with, especially in the U.S., but around the world.
            Therefore, I hope this experience has opened everyone’s mind to the
            privileges we have to choose, to travel, to study, to pay for
            things.” She’s grateful that “despite everything we&rsquo;ve gone
            through or weren&rsquo;t able to do, I do not feel like the last
            year of my life was a waste .... Although we did not share lunch in
            our makeshift cafeteria, I know my peers did their best to survive
            and I couldn&rsquo;t be more proud of them, of us.”
          </p>
        </Core.ContentSection>

        <Core.MoreResources>
          To stay up to date on SAIS experts in the news, visit{' '}
          <a
            href="http://www.sais-jhu.edu/opt-in"
            target="_blank"
            rel="noopener noreferrer"
          >
            sais-jhu.edu/opt-in
          </a>{' '}
          and select the option for <Core.BoldText>The Brief</Core.BoldText>,
          which is produced monthly by the Office of Marketing, Communications,
          and Strategic Initiatives.
        </Core.MoreResources>
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    erikJones2: file(
      relativePath: { regex: $directory }
      name: { eq: "Erik-Jones 2" }
    ) {
      ...ImagePragraphWrapImage
    }
    conflict: file(
      relativePath: { regex: $directory }
      name: { eq: "inthenews_conflict" }
    ) {
      ...MarginaliaImage
    }
    engagement: file(
      relativePath: { regex: $directory }
      name: { eq: "inthenews_engagement" }
    ) {
      ...MarginaliaImage
    }
    femaleProfessors: file(
      relativePath: { regex: $directory }
      name: { eq: "inthenews_female_professors" }
    ) {
      ...MarginaliaImage
    }
    migration: file(
      relativePath: { regex: $directory }
      name: { eq: "inthenews_migration" }
    ) {
      ...MarginaliaImage
    }
    usChina: file(
      relativePath: { regex: $directory }
      name: { eq: "inthenews_us_china" }
    ) {
      ...MarginaliaImage
    }
    kentCalder: file(
      relativePath: { regex: $directory }
      name: { eq: "kent_calder" }
    ) {
      ...ImagePragraphWrapImage
    }
    saisBolognaImpact: file(
      relativePath: { regex: $directory }
      name: { eq: "sais_bologna_impact" }
    ) {
      ...ContentWidthImage
    }
  }
`;

export default DepartmentContent;
